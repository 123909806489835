/* UserSearchInput.css */
.user-search-root {
    position: relative;
    width: 100%;
}

.suggestions-container {
    position: absolute;
    z-index: 10;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    border: var(--border-line-gmail-style);
    border-radius: 0 0 8px 8px;
}

.suggestion-item {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
}

.suggestion-item:hover {
    background-color: var(--light-grey);
}

.suggestion-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--blue);
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-right: 12px;
    font-family: var(--text);
}

.suggestion-text {
    display: flex;
    flex-direction: column;
    font-family: var(--text);
}

.suggestion-name {
    font-weight: 500;
    color: var(--black);
}

.suggestion-email {
    font-size: 12px;
    color: var(--grey);
}

/* Override Material-UI TextField styles */
.user-search-root .MuiOutlinedInput-root {
    border-radius: 8px;
}

.user-search-root .MuiOutlinedInput-notchedOutline {
    border-color: var(--border-line-gmail-style-color);
}

.user-search-root .MuiInputLabel-outlined {
    color: var(--grey);
}

.user-search-root .MuiInputLabel-outlined.Mui-focused {
    color: var(--blue);
}