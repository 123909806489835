.transcription-container {
    /* Remove max-height completely */
    max-height: none;
    /* Set exact height to match the speaking time card */
    height: 100%; /* Change to 100% to fill the container */
    padding: 8px 16px 16px; /* Reduce padding to create less space */
    background-color: #fff;
    overflow-y: auto; /* Only one scroll at the container level */
    /* No nested scrolling */
    font-family: 'Roboto', Arial, sans-serif; /* Consistent font family */
}

.speaker-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 0px;
}
  
.transcription-item {
    margin-bottom: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    color: #00331c;
}

/* Make sure the last item has extra bottom margin */
.transcription-item:last-child {
    margin-bottom: 70px; /* Increased bottom margin on the last item */
}

.speaker {
    font-weight: 800;
    color: inherit; /* Will inherit the speaker-specific color */
    margin-left: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px; /* Consistent font size */
}

/* Ensure the transcript takes the full height and no double scroll */
.dashboard-card.transcript {
    display: flex;
    flex-direction: column;
    margin-bottom: 0 !important; /* Override any margin that might be causing alignment issues */
    height: calc(100% - 0px) !important; /* Ensure it takes full height minus any needed adjustment */
}

.dashboard-card.transcript .transcript-container {
    flex-grow: 1;
}

/* Ensure the text at the bottom is visible */
.transcription-container::after {
    content: '';
    display: block;
    height: 30px; /* Reduced from 70px to 30px */
    width: 100%;
}

.speaker-edit-input {
    font-weight: bold;
    color: #00331c; /* Darker Racing Green */
    margin-left: 10px;
    border: none;
    border-bottom: 1px solid #444;
    outline: none;
    background: transparent;
    font-size: 14px; /* Consistent font size */
}

.edit-icon {
    cursor: pointer;
    font-size: 16px !important;
    color: #426853; /* Muted green to match the rest of the app */
    opacity: 0.7;
    transition: opacity 0.2s;
}

.edit-icon:hover {
    opacity: 1;
}

.text {
    color: #00331c; /* Darker Racing Green */
    margin-top: 1px;
    display: inline-block;
    max-width: 95%;
    position: relative;
    line-height: 1.5;
    font-size: 14px; /* Consistent font size */
}

.text.current-user {
    align-self: flex-end;
    color: #004d1a; /* Racing green shade */
}

.text.other-user {
    align-self: flex-start;
}

.time {
    font-size: 12px; /* Aligned with other small text */
    color: #426853; /* Muted green */
    display: inline-block;
    margin-left: 8px;
}

.transcription-item.current-user {
    align-items: flex-end;
}

.transcription-item.other-user {
    align-items: flex-start;
}

.highlighted {
    scroll-margin-top: 50px;
    scroll-margin-bottom: 50px;
}

.highlighted-text {
    background-color: rgba(0, 102, 34, 0.1) !important; /* Racing green with low opacity */
}

.bounce-animation {
    animation: bounce 0.5s;
    transform-origin: center center;
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(0);
    }
    75% {
        transform: translateY(-2px);
    }
}

.speaker-box {
    background-color: #FFFFFF;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    max-height: 350px;
    overflow-y: auto;
    min-width: 200px;
    font-family: 'Roboto', Arial, sans-serif; /* Consistent font family */
}

.speaker-box-item {
    position: relative;
    padding: 8px 12px;
    cursor: pointer;
    z-index: 10;
    border-radius: 4px;
    transition: background-color 0.2s;
    font-size: 14px; /* Consistent font size */
    color: #00331c; /* Darker Racing Green */
}

.option-box {
    display: block;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    padding: 10px;
    z-index: 20;
    white-space: nowrap;
    min-width: 200px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out, transform 0.2s ease;
    opacity: 0;
    transform: translateY(-5px);
    font-family: 'Roboto', Arial, sans-serif; /* Consistent font family */
}

.option-box-item {
    padding: 8px 12px;
    border-radius: 4px;
    transition: background-color 0.2s;
    font-size: 14px; /* Consistent font size */
    color: #00331c; /* Darker Racing Green */
}

.option-box.show {
    opacity: 1;
    transform: translateY(0);
}

.speaker-box-item:hover,
.option-box .option-box-item:hover {
    background-color: rgba(0, 66, 37, 0.05); /* Racing green with low opacity */
}

.edit-box .speaker-box-item:hover {
    background-color: rgba(0, 66, 37, 0.05); /* Racing green with low opacity */
}

/* Search input styling */
.search-input-wrapper {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #f5f5f5;
}

.search-input {
    flex: 1;
    font-size: 14px;
    font-family: 'Roboto', Arial, sans-serif; /* Consistent font family */
}

/* Selected speaker styling */
.selected-speaker {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #004225; /* British Racing Green */
}

.selected-icon {
    margin-right: 8px;
    color: #004225; /* British Racing Green */
}

/* Ensure the transcript takes the full height and no double scroll */
.dashboard-card.transcript {
    display: flex;
    flex-direction: column;
}

.dashboard-card.transcript .transcript-container {
    flex-grow: 1;
}

/* Ensure the text at the bottom is visible */
.transcription-container::after {
    content: '';
    display: block;
    height: 30px; /* Reduced from 50px to 30px */
    width: 100%;
}

/* Adjust for mobile devices */
@media (max-width: 960px) {
    .transcription-container {
        height: 450px; /* Match the card-content.static-content height on mobile */
        font-size: 13px; /* Slightly smaller font on mobile */
    }
    
    .text {
        font-size: 13px; /* Slightly smaller text on mobile */
    }
}
