.about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
}

.about-title {
    color: #333;
    margin-bottom: 2rem;
    text-align: center;
}

.about-message {
    color: #666;
    text-align: center;
}