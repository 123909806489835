.interaction-map-container {
    width: 100%;
    height: 350px; /* Increased height for better visualization */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    background-color: #ffffff;
}

.interaction-map {
    width: 100%;
    height: 100%;
}

.interaction-map-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: #666;
    font-weight: 500;
    text-align: center;
    padding: 20px;
}

/* Custom tooltips for better readability */
.vis-tooltip {
    position: absolute;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.95);
    color: #004225;
    border: 1px solid #004225;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    pointer-events: none;
    z-index: 1000;
    font-weight: 500;
}

/* Improve node label visibility */
.vis-network .vis-node .vis-label {
    text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.9);
    font-weight: 500;
    color: #ffffff; /* Changed to white for better contrast with darker nodes */
}

/* Make labels clearer on very dark nodes */
.vis-network .vis-node .vis-label {
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
}
