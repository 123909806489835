/* Only keep unique/overriding styles */
.transcript-editor {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Standardize the main container gap */
}

.section-title {
    color: var(--black-65);
    font-family: var(--text);
    font-weight: 500;
    margin-bottom: 1rem;
}

.transcript-line {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    background-color: var(--white);
    border: var(--border-line-gmail-style);
    border-radius: 7px;
    box-shadow: var(--box-shadow);
    margin-bottom: 0.5rem; /* Add space between transcript lines */
}

.audio-row {
    padding: 0.5rem;
    background-color: var(--black-3);
    border-radius: 4px;
    width: 100%;
    margin: 0; /* Remove any default margins */
    box-sizing: border-box; /* Ensure padding is included in width */
}

.audio-section {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
    padding: 0 0.5rem; /* Add some horizontal padding */
}

.slider-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    flex-grow: 1;
    width: calc(100% - 48px); /* Account for play button width */
}

.time-info,
.duration-info {
    color: var(--black-65);
    font-size: 0.75rem;
    white-space: nowrap;
}

.play-button {
    color: var(--blue);
    padding: 4px;
}

.play-button:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

/* Override Material-UI Select styles to match modal theme */
.MuiSelect-select {
    border-radius: 8px;
}

.MuiOutlinedInput-notchedOutline {
    border-color: var(--border-line-gmail-style-color) !important;
}

@media (max-width: 768px) {
    .slider-container {
        flex-direction: column;
        align-items: flex-start;
    }
}
