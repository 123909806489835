.analytics-speaker-ratio-chart {
    border: var(--border-line-gmail-style);
    border-radius: 8px;
    padding: 16px;
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    height: 100%;
}

.analytics-speaker-ratio-chart h6 {
    margin-top: 0;
    margin-bottom: 16px;
    font-family: var(--display);
    color: var(--black);
}

.empty-data-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.5);
    font-style: italic;
}