.settings-subtitle {
    /*margin-top: 20px;*/
}

.settings-description {
    color: grey; /* Grey color for descriptions */
}
.option-description {
    color: grey; /* Grey color for descriptions */
    margin: -5px 0 10px 32px !important;
}
