/* AnalyticsIndicators.css */
.indicator-bar {
    margin-bottom: 16px;
}

.indicator-progress {
    display: flex;
    align-items: center;
    width: 100%;
}

.indicator-no-data {
    color: rgba(0, 0, 0, 0.5);
    font-style: italic;
}

.MuiLinearProgress-root {
    height: 10px;
    border-radius: 5px;
    flex-grow: 1;
    margin-right: 8px;
}

.indicator-score {
    min-width: 35px;
    text-align: right;
}