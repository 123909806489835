/* LandingPage.css - Racing Green Dark Theme - SCOPED */
.landing-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #001a10; /* Even darker racing green background */
  position: relative;
  overflow-x: hidden;
}

/* Updated dark gradient mesh background */
.landing-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #002a18 0%, #00140c 50%, #002a18 100%);
  opacity: 0.95;
  z-index: -1;
  pointer-events: none;
}

/* ---------- SIMPLIFIED GRADIENT SHAPES SYSTEM ---------- */
/* Primary background shape - top left */
.landing-page .gradient-shape-primary {
  position: absolute;
  left: -10%;
  top: -5%;
  height: 800px;
  width: 800px;
  border-radius: 50%;
  background: radial-gradient(circle at center, rgba(32, 193, 122, 0.25) 0%, rgba(32, 193, 122, 0.1) 70%, transparent 100%);
  filter: blur(60px);
  z-index: 0;
  pointer-events: none;
  opacity: 0.8;
  will-change: transform;
  animation: landing-floating-primary 90s infinite alternate ease-in-out;
}

/* Secondary background shape - bottom right */
.landing-page .gradient-shape-secondary {
  position: absolute;
  right: -15%;
  bottom: 10%;
  height: 700px;
  width: 700px;
  border-radius: 50%;
  background: radial-gradient(circle at center, rgba(48, 216, 143, 0.2) 0%, rgba(48, 216, 143, 0.08) 70%, transparent 100%);
  filter: blur(50px);
  z-index: 0;
  pointer-events: none;
  opacity: 0.8;
  will-change: transform;
  animation: landing-floating-secondary 110s infinite alternate ease-in-out;
}

/* Accent background shape - mid-right */
.landing-page .gradient-shape-accent {
  position: absolute;
  right: 15%;
  top: 30%;
  height: 500px;
  width: 500px;
  border-radius: 50%;
  background: radial-gradient(circle at center, rgba(64, 218, 159, 0.2) 0%, rgba(64, 218, 159, 0.08) 70%, transparent 100%);
  filter: blur(40px);
  z-index: 0;
  pointer-events: none;
  opacity: 0.75;
  will-change: transform;
  animation: landing-floating-accent 70s infinite alternate ease-in-out;
}

/* Additional racing green glow in center */
.landing-page .gradient-shape-center {
  position: absolute;
  left: 30%;
  top: 40%;
  height: 600px;
  width: 600px;
  border-radius: 50%;
  background: radial-gradient(circle at center, rgba(32, 193, 122, 0.15) 0%, rgba(32, 193, 122, 0.05) 70%, transparent 100%);
  filter: blur(70px);
  z-index: 0;
  pointer-events: none;
  opacity: 0.7;
  will-change: transform;
  animation: landing-floating-primary 80s infinite alternate ease-in-out;
}

/* Keep backward compatibility with old shape classes */
.landing-page .gradient-shape-large-1 {
  position: absolute;
  left: -16rem;
  top: -12rem;
  height: 700px;
  width: 700px;
  border-radius: 9999px;
  background: linear-gradient(135deg, rgba(0, 77, 37, 0.3), rgba(32, 193, 122, 0.2));
  filter: blur(80px);
  z-index: 0;
  pointer-events: none;
  opacity: 0.8;
  will-change: transform;
  animation: landing-floating-primary 90s infinite alternate ease-in-out;
}

.landing-page .gradient-shape-large-2 {
  position: absolute;
  right: -16rem;
  top: 45%;
  height: 750px;
  width: 750px;
  border-radius: 9999px;
  background: linear-gradient(225deg, rgba(32, 193, 122, 0.25), rgba(0, 77, 37, 0.18));
  filter: blur(95px);
  z-index: 0;
  pointer-events: none;
  opacity: 0.8;
  will-change: transform;
  animation: landing-floating-secondary 110s infinite alternate ease-in-out;
}

.landing-page .gradient-shape-large-3 {
  position: absolute;
  left: 30%;
  bottom: -20rem;
  height: 600px;
  width: 600px;
  border-radius: 9999px;
  background: linear-gradient(45deg, rgba(0, 77, 37, 0.18), rgba(32, 193, 122, 0.25));
  filter: blur(70px);
  z-index: 0;
  pointer-events: none;
  opacity: 0.8;
  will-change: transform;
  animation: landing-floating-accent 70s infinite alternate ease-in-out;
}

/* ---------- FLOATING INTERACTIVE ELEMENTS ---------- */
/* Floating element base styles */
.landing-page .floating-element {
  position: absolute;
  z-index: 0;
  pointer-events: none;
  opacity: 0.2;
  will-change: transform;
  border-radius: 50%;
  mix-blend-mode: soft-light;
  filter: blur(2px);
}

.landing-page .floating-element-1 {
  width: 300px;
  height: 300px;
  top: 15%;
  left: 10%;
  background: radial-gradient(circle at 30% 40%, rgba(64, 218, 159, 0.8), rgba(64, 218, 159, 0.3) 70%, transparent 100%);
  animation: landing-float-element-1 45s infinite ease-in-out;
}

.landing-page .floating-element-2 {
  width: 250px;
  height: 250px;
  bottom: 20%;
  left: 15%;
  background: radial-gradient(circle at 70% 30%, rgba(48, 216, 143, 0.8), rgba(48, 216, 143, 0.3) 70%, transparent 100%);
  animation: landing-float-element-2 60s infinite ease-in-out;
}

.landing-page .floating-element-3 {
  width: 200px;
  height: 200px;
  top: 25%;
  right: 10%;
  background: radial-gradient(circle at 40% 60%, rgba(32, 193, 122, 0.8), rgba(32, 193, 122, 0.3) 70%, transparent 100%);
  animation: landing-float-element-3 50s infinite ease-in-out;
}

/* Keep backward compatibility with old shape classes */
.landing-page .floating-shape {
  position: absolute;
  border-radius: 50%;
  opacity: 0.25;
  z-index: 0;
  mix-blend-mode: soft-light;
  pointer-events: none;
  filter: blur(2px);
  will-change: transform;
}

.landing-page .floating-shape-1 {
  width: 250px;
  height: 250px;
  background: radial-gradient(circle at 30% 40%, rgba(32, 193, 122, 0.7), rgba(0, 117, 58, 0.3));
  top: 15%;
  left: 12%;
  animation: landing-float-element-1 45s infinite ease-in-out;
}

.landing-page .floating-shape-2 {
  width: 180px;
  height: 180px;
  background: radial-gradient(circle at 70% 60%, rgba(32, 193, 122, 0.7), rgba(0, 117, 58, 0.3));
  top: 65%;
  right: 15%;
  animation: landing-float-element-2 60s infinite ease-in-out;
}

.landing-page .floating-shape-3 {
  width: 300px;
  height: 300px;
  background: radial-gradient(circle at 40% 50%, rgba(0, 77, 37, 0.6), rgba(32, 193, 122, 0.3));
  bottom: 10%;
  left: 5%;
  animation: landing-float-element-3 50s infinite ease-in-out;
}

.landing-page .floating-shape-4 {
  width: 120px;
  height: 120px;
  background: radial-gradient(circle at 60% 30%, rgba(32, 193, 122, 0.7), rgba(0, 77, 37, 0.3));
  top: 20%;
  right: 18%;
  animation: landing-float-element-1 42s infinite ease-in-out;
}

.landing-page .floating-shape-5 {
  width: 80px;
  height: 80px;
  background: radial-gradient(circle at 50% 50%, rgba(0, 117, 58, 0.6), rgba(32, 193, 122, 0.3));
  top: 45%;
  left: 20%;
  animation: landing-float-element-2 55s infinite ease-in-out;
}

.landing-page .floating-shape-6 {
  width: 150px;
  height: 150px;
  background: radial-gradient(circle at 30% 70%, rgba(32, 193, 122, 0.5), rgba(0, 77, 37, 0.25));
  top: 70%;
  right: 28%;
  animation: landing-float-element-3 48s infinite ease-in-out;
}

/* ---------- SIMPLIFIED ANIMATIONS ---------- */
/* Slower, more subtle animations with single transformations */
@keyframes landing-floating-primary {
  0% { transform: translate(0, 0) scale(1); }
  100% { transform: translate(2%, 2%) scale(1.05); }
}

@keyframes landing-floating-secondary {
  0% { transform: translate(0, 0) scale(1); }
  100% { transform: translate(-2%, -1%) scale(1.03); }
}

@keyframes landing-floating-accent {
  0% { transform: translate(0, 0) scale(1); }
  100% { transform: translate(-1%, 2%) scale(1.05); }
}

@keyframes landing-float-element-1 {
  0% { transform: translate(0, 0); }
  50% { transform: translate(-20px, 20px); }
  100% { transform: translate(0, 0); }
}

@keyframes landing-float-element-2 {
  0% { transform: translate(0, 0); }
  50% { transform: translate(20px, -15px); }
  100% { transform: translate(0, 0); }
}

@keyframes landing-float-element-3 {
  0% { transform: translate(0, 0); }
  50% { transform: translate(-15px, -10px); }
  100% { transform: translate(0, 0); }
}

/* Logo styling to prevent overflow */
.landing-page .logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 40px;
  width: auto;
  overflow: hidden;
  position: relative;
  z-index: 5;
}

.landing-page .logo-image {
  height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
  filter: brightness(1.2); /* Adjusted for better visibility */
}

.landing-page .logo-container:hover .logo-image {
  transform: scale(1.05);
  filter: brightness(1.3);
}

/* Glass-morphism for header - SCOPED */
.landing-page .MuiAppBar-root {
  background-color: rgba(0, 20, 12, 0.85) !important;
  backdrop-filter: blur(10px) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3) !important;
  border-bottom: 1px solid rgba(32, 193, 122, 0.15) !important;
  position: relative;
  z-index: 10;
}

/* Enhanced primary buttons with racing green gradient - SCOPED */
.landing-page .MuiButton-containedPrimary:not(.linkedin-button) {
  background: linear-gradient(135deg, #00753a, #20c17a) !important;
  color: #FFFFFF !important;
  border-radius: 8px !important;
  transition: all 0.3s ease !important;
  text-transform: none !important;
  font-weight: 500 !important;
  box-shadow: 0 4px 20px rgba(0, 117, 58, 0.4) !important;
  position: relative;
  overflow: hidden;
  z-index: 5;
}

.landing-page .MuiButton-containedPrimary:not(.linkedin-button):hover {
  background: linear-gradient(135deg, #005f2e, #1aaa6a) !important;
  box-shadow: 0 6px 25px rgba(0, 117, 58, 0.6) !important;
  transform: translateY(-2px);
}

.landing-page .MuiButton-containedPrimary:not(.linkedin-button):active {
  transform: translateY(1px);
  box-shadow: 0 2px 10px rgba(0, 117, 58, 0.35) !important;
}

/* Button ripple effect */
.landing-page .MuiButton-containedPrimary:not(.linkedin-button)::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%, -50%);
  transform-origin: 50% 50%;
}

.landing-page .MuiButton-containedPrimary:not(.linkedin-button):focus:not(:active)::after {
  animation: landing-ripple 1s ease-out;
}

@keyframes landing-ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    transform: scale(200, 200);
    opacity: 0;
  }
}

/* Glass effect for outlined buttons - SCOPED */
.landing-page .MuiButton-outlinedPrimary {
  border: 1px solid rgba(32, 193, 122, 0.6) !important;
  color: #30d88f !important;
  border-radius: 8px !important;
  transition: all 0.3s ease !important;
  text-transform: none !important;
  font-weight: 500 !important;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 50, 30, 0.4) !important;
  position: relative;
  z-index: 5;
}

.landing-page .MuiButton-outlinedPrimary:hover {
  background-color: rgba(32, 193, 122, 0.15) !important;
  border-color: #30d88f !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(32, 193, 122, 0.25);
}

.landing-page .MuiButton-outlinedPrimary:active {
  transform: translateY(1px);
}

/* Text gradient for hero text */
.landing-page .gradient-text {
  background: linear-gradient(to right, #20c17a, #40da9f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

/* Rotating text animation enhancement */
.landing-page .rotating-text-container {
  display: inline-block;
  min-width: 200px;
  position: relative;
  z-index: 2;
}

.landing-page .rotating-text {
  display: inline-block;
  color: #30d88f;
  position: relative;
}

.landing-page .rotating-text::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -5px;
  left: 0;
  background: linear-gradient(90deg, #00753a, #30d88f);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}

.landing-page .rotating-text.active::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Enhanced feature cards with glass morphism - SCOPED */
.landing-page .feature-card {
  background: rgba(0, 50, 30, 0.85) !important;
  /* background: rgba(0, 50, 30, 0.7); */
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(32, 193, 122, 0.15);
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
}

.landing-page .feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.35);
  border-color: rgba(32, 193, 122, 0.3);
  background: rgba(0, 57, 35, 0.8);
}

.landing-page .feature-icon-wrapper {
  background: linear-gradient(135deg, rgba(0, 77, 37, 0.3), rgba(32, 193, 122, 0.15));
  width: 56px;
  height: 56px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  transition: all 0.3s ease;
}

.landing-page .feature-card:hover .feature-icon-wrapper {
  background: linear-gradient(135deg, rgba(0, 77, 37, 0.4), rgba(32, 193, 122, 0.25));
  transform: rotate(5deg);
}

/* Feature icons - SCOPED */
.landing-page .feature-icon {
  color: #30d88f;
  font-size: 28px;
  transition: all 0.3s ease;
}

.landing-page .feature-card:hover .feature-icon {
  transform: scale(1.1);
  color: #40da9f;
}

/* Enhanced input fields - SCOPED */
.landing-page .MuiOutlinedInput-root {
  border-radius: 8px !important;
  transition: all 0.3s ease !important;
  background-color: rgba(0, 57, 35, 0.6);
  backdrop-filter: blur(5px);
  position: relative;
  z-index: 2;
}

.landing-page .MuiOutlinedInput-root:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 65, 40, 0.7);
}

.landing-page .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(32, 193, 122, 0.5) !important;
}

.landing-page .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #20c17a !important;
  border-width: 2px !important;
}

.landing-page .MuiOutlinedInput-root.Mui-focused {
  box-shadow: 0 0 0 3px rgba(32, 193, 122, 0.2);
}

/* LinkedIn button with matching gradient - SCOPED */
.landing-page .linkedin-button {
  background: linear-gradient(90deg, #00753a, #20c17a) !important;
  transition: all 0.3s ease !important;
  box-shadow: 0 4px 15px rgba(0, 117, 58, 0.4) !important;
  position: relative;
  z-index: 5;
}

.landing-page .linkedin-button:hover {
  background: linear-gradient(90deg, #005f2e, #1aaa6a) !important;
  box-shadow: 0 6px 20px rgba(0, 117, 58, 0.6) !important;
  transform: translateY(-2px);
}

.landing-page .linkedin-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 8px rgba(0, 117, 58, 0.35) !important;
}

/* Footer links animation - SCOPED */
.landing-page .footer-link {
  position: relative;
  transition: all 0.3s ease;
  color: rgba(220, 220, 220, 0.7) !important;
}

.landing-page .footer-link:hover {
  color: #30d88f !important;
}

.landing-page .footer-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: #30d88f;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.landing-page .footer-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Dark mode text adjustments - SCOPED */
.landing-page {
  color: #F0F0F0;
}

.landing-page .MuiTypography-root {
  color: #F0F0F0 !important;
}

.landing-page .MuiTypography-colorTextSecondary {
  color: #CCCCCC !important;
}

/* Input text color for dark mode - SCOPED */
.landing-page .MuiInputBase-input {
  color: #F0F0F0 !important;
}

.landing-page .MuiInputBase-input::placeholder {
  color: #AAAAAA !important;
  opacity: 1;
}

/* Form helper text styles - SCOPED */
.landing-page .error-message {
  color: #ff6b6b !important;
}

.landing-page .loading-message {
  color: #30d88f !important;
}

/* Loading spinner color - SCOPED */
.landing-page .loading-spinner {
  color: #FFFFFF !important;
}

/* ---------- RESPONSIVE ADJUSTMENTS ---------- */
/* Simplify shapes on smaller screens */
@media (max-width: 768px) {
  .landing-page .gradient-shape-primary,
  .landing-page .gradient-shape-secondary,
  .landing-page .gradient-shape-accent,
  .landing-page .gradient-shape-center {
    opacity: 0.5; /* Increase opacity on mobile */
    filter: blur(40px); /* Less blur for better performance */
  }
  
  .landing-page .floating-element {
    opacity: 0.2; /* More subtle on mobile */
  }
  
  /* Scale down large shapes on mobile */
  .landing-page .gradient-shape-primary {
    width: 500px;
    height: 500px;
  }
  
  .landing-page .gradient-shape-secondary {
    width: 400px;
    height: 400px;
  }
  
  .landing-page .gradient-shape-accent {
    width: 300px;
    height: 300px;
  }
  
  .landing-page .gradient-shape-center {
    width: 350px;
    height: 350px;
  }
}

@media (max-width: 600px) {
  .landing-page .gradient-text {
    display: inline;
  }
  
  .landing-page .floating-element {
    opacity: 0.15;
  }
  
  /* Further reduce animations on very small screens for performance */
  .landing-page .floating-element-1,
  .landing-page .floating-element-2,
  .landing-page .floating-element-3 {
    animation-duration: 80s;
  }
}

/* ---------- CONTENT HIERARCHY IMPROVEMENTS ---------- */
/* Ensure content remains visually prominent */

/* Add subtle highlighting to important content sections */
.landing-page .hero-title,
.landing-page section h2,
.landing-page section h3 {
  position: relative;
  z-index: 2; /* Ensure content is above decorative elements */
}

/* Make section containers have proper z-index */
.landing-page section, 
.landing-page .MuiContainer-root {
  position: relative;
  z-index: 1;
}

/* Fix for any potential z-index issues in nested components */
.landing-page .MuiPaper-root {
  isolation: isolate;
}

/* Fix bounce animation that was referenced in JSX but not defined */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(10px);
  }
  60% {
    transform: translateY(5px);
  }
}
