/* Base container styling */
.dashboard-container {
    padding: 0px;
    width: 100%;
    overflow-x: visible; /* Changed from hidden to visible */
    overflow-y: visible; /* Added to ensure no vertical scrolling container */
    min-height: auto; /* Changed from calc to auto */
    font-family: 'Roboto', Arial, sans-serif; /* Consistent font family */
}

/* Page header styling */
.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    margin-bottom: 0px;
    margin-top: 0; /* Ensure no gap at top */
    border-radius: 0px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 66, 37, 0.06);
    position: sticky;
    top: 0; /* Position at the very top */
    z-index: 10;
}

.dashboard-title {
    font-weight: 700;
    margin-right: 16px;
    color: #212121; /* Updated from #004225 to standard dark gray */
    font-size: 1.75rem;
}

.title-container {
    display: flex;
    align-items: center;
}

.dashboard-controls {
    display: flex;
    gap: 16px;
}

.view-select {
    min-width: 220px;
    background-color: white;
}

/* Dashboard content and card styling */
.dashboard-content {
    width: 100%;
    margin: 0;
    padding: 2px 2px; /* Add minimal horizontal padding to ensure side borders are visible */
}

/* Card styling */
.dashboard-card {
    display: flex;
    flex-direction: column;
    border-radius: 0px;
    background-color: white; /* Keep cards white for contrast */
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    margin-bottom: 16px;
    overflow: hidden; /* Keep overflow hidden to prevent content from spilling out */
    height: 100%; /* Ensure equal height */
    border-top: 2px solid #008040; /* Updated to lighter racing green (accent.green3) */
}

.dashboard-card:hover {
    box-shadow: 0 4px 10px rgba(0, 66, 37, 0.12);
    transform: translateY(-2px);
}

.dashboard-card.transcript {
    height: calc(100% - 16px); /* Account for margin-bottom */
}

.card-header {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F0F5F0; /* Updated from white to a light green background */
    position: sticky;
    top: 0;
    z-index: 2;
}

.card-title {
    display: flex;
    align-items: center;
    gap: 10px;
}


/* Give fixed height to visualization cards for consistency */
.card-content.static-content {
    height: 350px; /* Consistent height for all cards */
    overflow-y: auto; /* Allow scrolling within visualization cards */
}

/* Metadata styling with aligned typography */
.metadata-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 6px 4px;
    font-family: 'Roboto', Arial, sans-serif; /* Consistent font family */
}

.metadata-row {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 2px;
    transition: background-color 0.2s;
}

.metadata-row:hover {
    background-color: rgba(0, 66, 37, 0.05); /* Racing green with low opacity */
}

.metadata-icon {
    display: flex;
    align-items: center;
    color: #004225; /* British Racing Green */
    margin-right: 12px;
}

.metadata-label {
    color: #212121; /* Updated from #00331c to standard dark gray */
    font-weight: 600;
    width: 100px;
    flex-shrink: 0;
    font-size: 14px;
}

.metadata-value {
    color: #424242; /* Updated from #00331c to standard medium gray */
    flex-grow: 1;
    font-size: 14px;
}

.metadata-divider {
    margin: 8px 0;
    background-color: rgba(0, 66, 37, 0.1); /* Racing green with opacity */
}

.metadata-row.participants {
    flex-direction: column;
    align-items: flex-start;
}

.participants-header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    width: 100%;
}

.participants-header svg {
    margin-right: 12px;
    color: #004225; /* British Racing Green */
}

.metadata-participants-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 8px;
    width: 100%;
    margin-top: 4px;
}

.participant-chip {
    width: 100%;
    justify-content: flex-start;
    background-color: rgba(0, 66, 37, 0.04); /* Racing green with low opacity */
    border: 1px solid rgba(0, 66, 37, 0.1);
    transition: all 0.2s ease;
    font-size: 14px; /* Updated to match transcript font size */
}

.participant-chip:hover {
    background-color: rgba(0, 66, 37, 0.08); /* Racing green with higher opacity */
    border-color: rgba(0, 66, 37, 0.2);
}

/* Chart styling with typography alignment */
.chart-container {
    width: 100%;
    height: 100%;
    padding: 8px;
    font-family: 'Roboto', Arial, sans-serif; /* Consistent font family */
}

/* Ensure all chart text uses the same font family and color */
.recharts-text {
    font-family: 'Roboto', Arial, sans-serif !important;
    fill: #00331c !important; /* Use the same text color as the rest of the app */
}

.recharts-legend-item-text {
    color: #00331c !important; /* Same as text.primary in palette */
    font-family: 'Roboto', Arial, sans-serif !important;
    font-size: 12px !important;
}

/* Specific styling for speaking time chart */
.chart-container.speaking-time {
    min-height: 320px; /* Ensure minimum height for the chart */
    height: 100%;
    padding: 0; /* Remove padding to prevent scrollbar triggering */
    margin: 0 auto; /* Center the chart */
}

/* Alignment for pie chart labels */
.recharts-pie-label-text {
    font-family: 'Roboto', Arial, sans-serif !important;
    font-size: 12px !important;
    fill: #00331c !important;
}

/* Transcript container with consistent typography */
.transcription-container {
    height: 820px;
    overflow-y: auto;
    background-color: #ffffff;
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 14px;
}

/* Responsive styles */
@media (max-width: 960px) {
    .dashboard-container {
        padding: 0 2px; /* Minimal horizontal padding to ensure borders are visible */
    }
    
    .page-header {
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        position: relative;
        top: 0;
    }
    
    .title-container {
        margin-bottom: 16px;
        width: 100%;
    }
    
    .dashboard-controls {
        width: 100%;
    }
    
    .view-select {
        width: 100%;
    }
    
    .card-content.static-content {
        height: 380px; /* Taller on mobile for better viewing */
    }
    
    .transcript-container {
        height: 450px; /* Smaller height on mobile */
    }
    
    .metadata-participants-grid {
        grid-template-columns: 1fr;
    }
}

/* Custom animations for racing green theme */
@keyframes pulse-racing-green {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 66, 37, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(0, 66, 37, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 66, 37, 0);
    }
}

/* Apply animation to highlighted elements */
.dashboard-card:focus-within {
    animation: pulse-racing-green 2s infinite;
}

/* Scrollbar styling */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 66, 37, 0.05);
    border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 66, 37, 0.2);
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 66, 37, 0.4);
}

/* Status indicators */
.status-badge {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
}

.status-badge.active {
    background-color: rgba(0, 66, 37, 0.15);
    color: #004225;
}

.status-badge.pending {
    background-color: rgba(184, 134, 11, 0.15);
    color: #b8860b;
}

.status-badge.inactive {
    background-color: rgba(120, 120, 120, 0.15);
    color: #666;
}

/* Tooltips and popovers */
.tooltip, .popover {
    background-color: #004225;
    color: white;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.tooltip::after, .popover::after {
    border-color: #004225 transparent transparent transparent;
}

/* Selection styling */
::selection {
    background-color: rgba(0, 66, 37, 0.2);
    color: #000;
}
