@import '../root.css';

.black {
    color: var(--black);
}

.header-wrap {
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5rem;
    height: 4rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--black-5);
}

.main-navigation {
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*font-size: 1.5rem;*/
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    /*gap: 30px; !* 10px padding between Product and Company *!*/
}

.top-wordmark {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--black);
    text-decoration: none;
}

.show-backdrop {
    text-decoration: none;
    color: var(--black);
    padding: 1rem;
}


.greyed-out {
    color: var(--black-50); /* Use a lighter shade of black for greyed-out effect */
    pointer-events: none; /* Make non-clickable */
    cursor: default; /* Change cursor to default */
}

.elevaide-logo-image {
    width: 3rem;
    height: 3rem;
}

.elevaide-image {
    height: auto; /* Maintain aspect ratio */
    width: 8.3rem;
}
