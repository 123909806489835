/* Upload component styles to match company/team management styling */

/* Tab curved view styles - to match Company.js layout */
.tab-curved-view {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.tab-header {
    border-bottom: 1px solid rgba(0, 66, 37, 0.1);
    position: relative;
    flex-shrink: 0;
}

.tab-header::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 66, 37, 0.1);
}

.tab-panel {
    flex-grow: 1;
    overflow: auto;
    background-color: #fafafa;
}

/* Team selection area */
.team-select-area {
    margin-bottom: 24px;
}

/* File type selector styles */
.file-type-icon {
    transition: transform 0.2s, color 0.2s;
}

.file-type-icon:hover {
    transform: translateY(-2px);
    color: #004225;
}

/* Drop zone styles */
.drop-zone {
    position: relative;
    transition: all 0.2s ease;
}

.drop-zone:hover .drop-zone-overlay {
    opacity: 1;
}

.drop-zone-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 66, 37, 0.03);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;
}

/* Animation for upload icon */
@keyframes uploadArrow {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}

.upload-arrow {
    animation: uploadArrow 2s infinite ease-in-out;
}

/* Progress animation */
@keyframes progress {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

.progress-animation {
    animation: progress 0.5s ease;
}

/* Button styling to match the app's racing green theme */
.upload-button {
    text-transform: none;
    font-weight: 500;
    border-radius: 4px;
    box-shadow: none;
}

.upload-button:hover {
    box-shadow: 0 2px 4px rgba(0, 66, 37, 0.2);
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .tab-panel {
        padding: 16px;
    }
    
    .file-type-icons {
        gap: 16px;
    }
}
