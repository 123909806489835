.chat-with-ai-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
}

.chat-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 220px);
    overflow: hidden;
}

.chat-messages-wrapper {
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: 16px;
    scrollbar-width: thin;
}

.chat-messages-wrapper::-webkit-scrollbar {
    width: 6px;
}

.chat-messages-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(0, 66, 37, 0.2);
    border-radius: 3px;
}

.chat-messages-wrapper::-webkit-scrollbar-track {
    background: transparent;
}

.chat-messages {
    display: flex;
    flex-direction: column;
    background-color: #f8f9f8;
    min-height: 100%;
    padding: 16px;
}

.message {
    margin-bottom: 16px;
    padding: 12px 16px;
    border-radius: 12px;
    max-width: 85%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(8px); }
    to { opacity: 1; transform: translateY(0); }
}

.message.user {
    align-self: flex-end;
    background-color: #004225;
    color: #ffffff;
    border-bottom-right-radius: 4px;
}

.message.assistant {
    align-self: flex-start;
    background-color: #ffffff;
    color: #00331c;
    border: 1px solid rgba(0, 66, 37, 0.1);
    border-bottom-left-radius: 4px;
}

.message.assistant p {
    margin: 0 0 10px 0;
    line-height: 1.5;
}

.message.assistant p:last-child {
    margin-bottom: 0;
}

.message.assistant strong {
    font-weight: 600;
    color: #004225;
}

.message.assistant h1,
.message.assistant h2,
.message.assistant h3,
.message.assistant h4,
.message.assistant h5,
.message.assistant h6 {
    margin-top: 16px;
    margin-bottom: 8px;
    color: #004225;
    font-weight: 600;
}

.message.assistant h1 {
    font-size: 1.5rem;
}

.message.assistant h2 {
    font-size: 1.3rem;
}

.message.assistant h3 {
    font-size: 1.1rem;
}

.message.assistant code {
    background-color: rgba(0, 66, 37, 0.05);
    padding: 2px 4px;
    border-radius: 4px;
    font-family: monospace;
    font-size: 0.9em;
}

.message.assistant pre {
    background-color: rgba(0, 66, 37, 0.05);
    padding: 12px;
    border-radius: 6px;
    overflow-x: auto;
    margin: 10px 0;
}

.chat-input-wrapper {
    padding: 16px;
    position: sticky;
    bottom: 0;
    background-color: var(--white);
    border-top: 1px solid rgba(0, 66, 37, 0.1);
    z-index: 10;
}

.chat-input {
    display: flex;
    gap: 12px;
    align-items: flex-end;
}

.message.assistant.loading {
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px dashed rgba(0, 66, 37, 0.2);
}

.conversation-sidebar {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 240px;
    background-color: #f8f9f8;
    border-left: 1px solid rgba(0, 66, 37, 0.1);
    overflow-y: auto;
    padding: 20px 16px;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.05);
    z-index: 20;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.conversation-sidebar.hidden {
    transform: translateX(100%);
    opacity: 0;
}

.conversation-sidebar.visible {
    transform: translateX(0);
    opacity: 1;
}

.conversation-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
    margin-bottom: 8px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    border: 1px solid transparent;
}

.conversation-item:hover {
    background-color: rgba(0, 66, 37, 0.05);
}

.conversation-item.selected {
    background-color: rgba(0, 66, 37, 0.08);
    border-color: rgba(0, 66, 37, 0.2);
}

.conversation-item.empty {
    font-style: italic;
    color: rgba(0, 51, 28, 0.5);
}

.new-conversation-button {
    width: 100%;
    margin-bottom: 20px !important;
    border-radius: 8px !important;
    background-color: #004225 !important;
    color: white !important;
    text-transform: none !important;
    font-weight: 500 !important;
    padding: 8px 16px !important;
    transition: background-color 0.2s ease !important;
}

.new-conversation-button:hover {
    background-color: #005c33 !important;
}

.send-button {
    min-width: 80px;
    border-radius: 8px !important;
    background-color: #004225 !important;
    color: white !important;
    text-transform: none !important;
    font-weight: 500 !important;
    transition: background-color 0.2s ease !important;
}

.send-button:hover {
    background-color: #005c33 !important;
}

.send-button:disabled {
    background-color: rgba(0, 66, 37, 0.3) !important;
}
