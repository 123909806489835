/* Remove .teams-container rule */
/* 
.teams-container {
    padding: 24px;
    height: calc(100vh - 180px); 
    display: flex;
    flex-direction: column;
} 
*/

/* Remove .teams-header rule */
/* 
.teams-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
} 
*/

/* Remove .teams-title rule if styling is fully handled by sx prop */
/*
.teams-title {
    font-weight: 500;
}
*/

/* Keep rules for content *inside* the panel */
.teams-grid-container { /* If this class is used, review its styles */
    flex-grow: 1;
    overflow-y: auto;
    /* Remove border, border-radius, padding if handled by tab-panel */
    /* border: 1px solid var(--border-line-gmail-style-color); */
    /* border-radius: 8px; */
    /* padding: 16px; */
}

.teams-grid {
    /*min-height: 100%;*/ /* Remove or keep commented - height is handled by flex */
}

.no-teams-message {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Remove height rule - rely on parent flexbox sizing */
    /* height: 100%; */ 
    color: var(--black-50);
    font-style: italic;
}
