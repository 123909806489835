/* frontend/src/product/App.css */

.navigation-button {
    color: #000000;
    margin-left: 8px;
}

.navigation-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.green-check {
    color: green;
}

.red-check {
    color: darkred;
}

.vibrating-icon {
    animation: shakePulse 2s linear infinite;
}

.tab-curved-view {
    border-radius: 2px;
    overflow: hidden;
    border: 1px solid #e0e0e0;
    margin-bottom: 0px;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.tab-header {
    border-radius: 2px 2px 0 0;
    background-color: #FFFFFF;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.tab-panel {
    padding: 20px;
    background-color: white;
    flex: 1;
}

@keyframes shakePulse {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    2% { transform: translate(-1px, -2px) rotate(-1deg); }
    4% { transform: translate(-3px, 0px) rotate(1deg); }
    6% { transform: translate(3px, 2px) rotate(0deg); }
    8% { transform: translate(1px, -1px) rotate(1deg); }
    10% { transform: translate(-1px, 2px) rotate(-1deg); }
    12% { transform: translate(-3px, 1px) rotate(0deg); }
    14% { transform: translate(3px, 1px) rotate(-1deg); }
    16% { transform: translate(-1px, -1px) rotate(1deg); }
    18% { transform: translate(1px, 2px) rotate(0deg); }
    20% { transform: translate(1px, -2px) rotate(-1deg) scale(1); }
    /*20% { transform: scale(1); }*/
    60% { transform: scale(1.2); }
    100% { transform: scale(1); }
}
