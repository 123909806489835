.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.05);
}

.modal-content {
    background-color: white;
    border-radius: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 720px;
    max-width: 90%;
    padding: 32px;
    max-height: 80vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.modal-title {
    text-align: center;
    margin-bottom: 24px !important;
}

.modal-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modal-body > * {
    margin: 0 !important;
}

.modal-save-button {
    display: block;
    width: 100%;
    border-radius: 17px !important;
    margin: 24px auto 0 !important;
}

@media (max-width: 900px) {
    .modal-overlay {
        align-items: flex-start;
        padding-top: 10px;
    }

    .modal-content {
        width: 80%;
        max-height: calc(100vh - 130px);
        padding: 24px;
        border-radius: 20px;
        margin-bottom: 10px;
    }

    .modal-body {
        gap: 8px;
    }
}