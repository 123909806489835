@import '../root.css';

.styled-sidebar {
    width: 80px;
    flex-shrink: 0;
    border-radius: 2px;
    margin-top: 7px;
    margin-left: 5px;
    height: calc(100vh - 10px);
    background-color: #E5EFE5;
    border: var(--border-line-gmail-style);
    box-sizing: border-box;
    box-shadow: var(--box-shadow);
    position: fixed;
    z-index: 999;
    transition: width 0.3s ease;
    display: flex;
    flex-direction: column;
}

/* Logo container styling */
.logo-container {
    display: flex;
    justify-content: center;
    padding: 8px 0;
}

.elevaide-logo-image {
    box-shadow: none !important;
}

/* Ensure the main list takes most of the space */
.sidebar-list {
    padding: 0;
    width: 100%;
}

.styled-list-item {
    color: var(--black);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    transition: all 0.2s ease;
    border-radius: 8px;
    margin: 4px 8px;
}

.styled-list-item:hover {
    color: var(--black);
    background-color: rgba(0, 0, 0, 0.08);
    transform: translateY(-1px);
}

.styled-list-item.active {
    background-color: rgba(0, 0, 0, 0.12);
    font-weight: 500;
}

.styled-list-item-icon {
    min-width: auto;
    color: var(--black) !important;
    justify-content: center;
    margin-bottom: 4px;
}

.styled-list-item-text {
    text-align: center;
}

.settings-item {
    margin-bottom: 10px;
}

.settings-active {
    background-color: rgba(0, 0, 0, 0.12);
}

@media (max-width: 900px) {
    .styled-sidebar {
        width: 100%;
        height: 60px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        border-radius: 0;
        border-top: var(--border-line-gmail-style);
    }

    .sidebar-list {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        padding: 0;
    }

    .styled-list-item {
        padding: 0;
        height: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .styled-list-item-icon {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
    }

    .styled-list-item-icon > * {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
    }

    .styled-list-item-text {
        display: none;
    }

    .settings-item {
        order: 1;
        margin-bottom: 0 !important;
    }
}
