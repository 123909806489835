/* Base container styling */
.heartbeat-container {
    padding: 0px;
    width: 100%;
    overflow-x: visible;
    overflow-y: visible;
    min-height: auto;
    font-family: 'Roboto', Arial, sans-serif;
}

/* Page header styling - consistent with MeetingAnalysis and Indicators */
.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    margin-bottom: 0px;
    margin-top: 0;
    border-radius: 0px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 66, 37, 0.06);
    position: sticky;
    top: 0;
    z-index: 10;
}

.dashboard-title {
    font-weight: 700;
    margin-right: 16px;
    color: #004225; /* British Racing Green */
    font-size: 1.75rem;
}

.title-container {
    display: flex;
    align-items: center;
}

.dashboard-controls {
    display: flex;
    gap: 16px;
}

/* Dashboard card styling consistent with MeetingAnalysis */
.dashboard-card {
    display: flex;
    flex-direction: column;
    border-radius: 0px;
    background-color: white;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    margin-bottom: 16px;
    overflow: hidden;
    height: 100%;
    border-top: 2px solid #004225; /* Racing Green */
}

.dashboard-card:hover {
    box-shadow: 0 4px 10px rgba(0, 66, 37, 0.12);
    transform: translateY(-2px);
}

.card-header {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 2;
}

.card-title {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #004225; /* Racing Green */
}

.card-title svg {
    color: #004225; /* Racing Green */
}

.card-title h6 {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #004225 !important; /* Racing Green */
    font-family: 'Roboto', Arial, sans-serif !important;
}

.card-content {
    padding: 12px;
    flex-grow: 1;
    font-family: 'Roboto', Arial, sans-serif;
    color: #00331c; /* Darker Racing Green for all text */
}

/* Audio playback bar styling */
.playback-card {
    width: 100%;
    border-radius: 0;
    margin-bottom: 16px;
}

.playback-content {
    width: 100%;
    padding: 16px;
}

.slider-container {
    width: 100%;
    padding: 0;
    margin-top: 8px;
}

.seek-bar {
    width: 100%;
    margin-bottom: 10px;
    color: #004225; /* Racing Green */
}

.controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0;
}

.time-display {
    color: #00331c; /* Darker Racing Green */
    font-size: 14px;
    flex-grow: 0;
    text-align: left;
}

.playback-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
}

/* Transcript tab styling */
.transcript {
    height: calc(100% - 16px);
    display: flex;
    flex-direction: column;
}

/* Tab panel styling */
.MuiTabPanel-root {
    padding: 0;
    height: 100%;
    overflow-y: auto;
}

/* Scrollbar styling - consistent with MeetingAnalysis */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 66, 37, 0.05);
    border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 66, 37, 0.2);
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 66, 37, 0.4);
}

/* Timeline container */
.timeline-container {
    width: 100%;
    margin-bottom: 16px;
}

/* Responsive styles */
@media (max-width: 960px) {
    .heartbeat-container {
        padding: 0 2px;
    }
    
    .playback-grid,
    .tab-view-grid {
        width: 100%;
    }
    
    .page-header {
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
    }
    
    .title-container {
        margin-bottom: 16px;
        width: 100%;
    }
    
    .dashboard-controls {
        width: 100%;
    }
}

/* Status indicators */
.status-badge {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
}

.status-badge.active {
    background-color: rgba(0, 66, 37, 0.15);
    color: #004225;
}

/* Selection styling */
::selection {
    background-color: rgba(0, 66, 37, 0.2);
    color: #000;
}
