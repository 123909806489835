.action-button-container {
    position: relative;
}

.popover-box {
    position: fixed;
    bottom: 100px;
    left: 15px;
    background-color: white;
    border-radius: 8px;
    z-index: 1002;
    padding: 8px 0;
    width: 200px;
    border: var(--border-line-gmail-style);
    box-shadow: var(--box-shadow);
}

.popover-box .MuiList-root {
    padding: 0;
}

.popover-box .MuiListItem-root {
    padding: 8px 16px;
    color: black;
}

.popover-box .MuiListItemText-primary {
    font-family: var(--text);
    font-weight: 100;
    font-size: 14px;
}

.popover-box .MuiListItem-divider {
    border-top: var(--border-line-gmail-style);
    margin-top: 4px;
}

@media (max-width: 900px) {
    .popover-box {
        left: auto;
        right: 10px;
        bottom: 65px; /* Adjusted to be above the mobile navigation bar */
        width: 140px; /* Maximum width to prevent it from getting too wide on larger phones */
    }
}