:root {
    --text: 'SanaSans', sans-serif;
    --display: 'SanaSansDisplay', sans-serif;
    --mono-font: 'mono-font', monospace;
    --black-100: #000000;
    --black: #0E0E0E;
    --black-65: rgba(0, 0, 0, 0.65);
    --black-50: rgba(0, 0, 0, 0.5);
    --black-35: rgba(0, 0, 0, 0.35);
    --black-10: rgba(0, 0, 0, 0.1);
    --black-5: rgba(0, 0, 0, 0.05);
    --black-3: rgba(246, 246, 247, 1);
    --white: #ffffff;
    --blue: rgba(70, 0, 210, 1);
    --grey: rgba(120, 120, 120, 1); /* Equivalent to #787878 */
    --light-grey: #EBF1FA; /* Light grey background */
    --border-line-gmail-style-color: #e0e0e0; /* Border to match Gmail style */
    --border-line-gmail-style: 1px solid var(--border-line-gmail-style-color); /* Light grey border */
    --box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Global Styles */
.grid-item {
    border: var(--border-line-gmail-style);
    border-radius: 7px;
    padding: 20px;
    background-color: var(--white);
    box-shadow: var(--box-shadow);
}

.grid-item-transcription {
    height: calc(100vh - 220px); /* Adjust this value as needed */
    overflow-y: auto;
    position: sticky;
    top: 100px; /* Adjust this value to match your layout */
}

/* Tab view */

.tab-curved-view {
    border-radius: 2px;
    overflow: hidden;
    border: 1px solid #e0e0e0;
    margin-bottom: 0px;
    background-color: white;
}

.tab-header {
    border-radius: 2px 2px 0 0;
    background-color: #FFFFFF;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.tab-panel {
    padding: 20px;
}
