.custom-tooltip {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    pointer-events: all;
    transform: translate(10px, -50%);
    z-index: 1000;
    width: 220px;
    max-width: 300px;
}

.custom-tooltip:hover {
    background-color: rgba(245, 245, 245, 0.9);
}

.custom-tooltip .label {
    font-weight: bold;
    margin: 5px 0;
}

.custom-tooltip .meeting-name {
    font-style: italic;
    margin-bottom: 10px;
}

.custom-tooltip .click-info {
    margin-top: 10px;
    font-size: 0.9em;
    color: #666;
    font-style: italic;
}

.custom-tooltip p {
    margin: 0;
    padding: 0;
}