/* Base container styling */
.indicators-container {
    padding: 0px;
    width: 100%;
    overflow-x: visible;
    overflow-y: visible;
    min-height: auto;
    font-family: 'Roboto', Arial, sans-serif;
}

/* Page header styling */
.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    margin-bottom: 0px;
    margin-top: 0;
    border-radius: 0px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 66, 37, 0.06);
    position: sticky;
    top: 0;
    z-index: 10;
}

.indicators-title {
    font-weight: 700;
    margin-right: 16px;
    color: #212121; /* Updated from #004225 to standard dark gray */
    font-size: 1.75rem;
}

.title-container {
    display: flex;
    align-items: center;
}

.indicators-controls {
    display: flex;
    gap: 16px;
}

.view-select {
    min-width: 220px;
    background-color: white;
}

/* Indicators content and card styling */
.indicators-content {
    width: 100%;
    margin: 0;
    padding: 2px 2px;
}

/* Card styling */
.indicator-card {
    display: flex;
    flex-direction: column;
    border-radius: 0px;
    background-color: white;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    margin-bottom: 16px;
    overflow: hidden;
    height: 100%;
    border-top: 2px solid #004225;
}

.indicator-card:hover {
    box-shadow: 0 4px 10px rgba(0, 66, 37, 0.12);
    transform: translateY(-2px);
}

.indicator-card.transcript {
    height: calc(100% - 16px);
}

.card-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 2;
}

.card-title {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #004225;
}

.card-title svg {
    color: #004225;
}

.card-content {
    padding: 12px;
    flex-grow: 1;
    font-family: 'Roboto', Arial, sans-serif;
    color: #00331c;
}

/* Chip container styling */
.chip-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    padding: 10px 15px;
}

.indicator-chip {
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 20px;
    background-color: rgba(0, 66, 37, 0.04);
    border: 1px solid rgba(0, 66, 37, 0.1);
    color: #004225;
}

.indicator-chip:hover {
    background-color: rgba(0, 66, 37, 0.08);
    border-color: rgba(0, 66, 37, 0.2);
}

.selected-chip {
    background-color: #004225 !important;
    color: #ffffff !important;
    border-color: #004225 !important;
    box-shadow: 0 2px 4px rgba(0, 66, 37, 0.2);
}

/* Analysis section styling */
.analysis-container {
    border-radius: 0px;
    border: 1px solid rgba(0, 66, 37, 0.1);
    padding: 0;
    overflow: hidden;
    margin-bottom: 20px;
}

.analysis-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: rgba(0, 66, 37, 0.02);
    border-bottom: 1px solid rgba(0, 66, 37, 0.1);
}

.analysis-title {
    font-size: 16px;
    font-weight: 600;
    color: #212121; /* Updated from #004225 to standard dark gray */
}

.analysis-score {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.score-value {
    font-size: 16px;
    font-weight: 600;
    color: #212121; /* Updated from #004225 to standard dark gray */
}

.analysis-content {
    padding: 16px;
    color: #424242; /* Updated from #00331c to standard medium gray */
    font-size: 14px;
    line-height: 1.6;
}

.improvement-container {
    margin-top: 16px;
    padding: 12px;
    background-color: rgba(0, 66, 37, 0.03);
    border-radius: 4px;
    border-left: 3px solid #004225;
}

.improvement-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #004225;
}

/* Statement box styling */
.statement-container {
    margin-bottom: 20px;
    border-radius: 0px;
    border: 1px solid rgba(0, 66, 37, 0.1);
    overflow: hidden;
}

.statement-header {
    padding: 12px 16px;
    background-color: rgba(0, 66, 37, 0.02);
    border-bottom: 1px solid rgba(0, 66, 37, 0.1);
}

.statement-title {
    font-size: 16px;
    font-weight: 600;
    color: #004225;
}

.statement-content {
    padding: 12px;
}

.statement-box {
    background-color: #F1F3F4;
    border-radius: 4px;
    padding: 12px 16px;
    margin-bottom: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-left: 3px solid transparent;
}

.statement-box:hover {
    background-color: #e9f0ea;
    border-left-color: #004225;
}

.statement {
    color: #00331c;
    font-size: 14px;
    line-height: 1.5;
}

/* Tooltip styling */
.custom-tooltip {
    background-color: white;
    border: 1px solid rgba(0, 66, 37, 0.2);
    border-radius: 4px;
    padding: 10px 14px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.tooltip-title {
    font-size: 14px;
    font-weight: 600;
    color: #004225;
    margin-bottom: 6px;
}

.tooltip-content {
    font-size: 13px;
    color: #00331c;
}

/* Transcription container */
.transcription-column {
    height: 100%;
}

.transcription-wrapper {
    height: 100%;
    border-top: 2px solid #004225;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 66, 37, 0.08);
}

.transcription-header {
    padding: 16px;
    display: flex;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid rgba(0, 66, 37, 0.1);
}

.transcription-title {
    font-size: 16px;
    font-weight: 600;
    color: #004225;
    display: flex;
    align-items: center;
    gap: 8px;
}

.transcription-title svg {
    color: #004225;
}

/* Responsive styles */
@media (max-width: 960px) {
    .page-header {
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
    }
    
    .title-container {
        margin-bottom: 16px;
        width: 100%;
    }
    
    .indicators-controls {
        width: 100%;
    }
    
    .view-select {
        width: 100%;
    }
    
    .chip-container {
        justify-content: center;
    }
}

/* Custom scrollbar */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 66, 37, 0.05);
    border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 66, 37, 0.2);
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 66, 37, 0.4);
}

/* Selection styling */
::selection {
    background-color: rgba(0, 66, 37, 0.2);
    color: #000;
}
