/* Timeline container - fixed styling to match reference */
.timeline-container {
  width: 100%;
  margin-bottom: 16px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.timeline-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.timeline-title {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 20px;
  font-family: 'Roboto', Arial, sans-serif;
}

/* Timeline content */
.timeline-content {
  padding: 12px;
  position: relative;
  overflow: hidden;
  /* Critical: Define a stacking context and ensure absolute children are contained */
  contain: layout paint;
  isolation: isolate;
  height: 100%;
  /* Remove any left border */
  background-color: white !important; /* Ensure white background */
}

/* Timeline sections */
.timeline-section {
  margin-bottom: 16px;
}

.timeline-section-title {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 8px;
}

.timeline-row {
  position: relative;
  height: 30px;
  background-color: #f9f9f9; /* Lighter background color */
  border-radius: 4px;
  overflow: hidden;
}

/* Speaker label */
.speaker-label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

/* Meeting timeline header */
.meeting-timeline-header {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  padding: 12px 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

/* Topics section */
.topics-section {
  margin-bottom: 20px;
}

.section-label {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 8px;
}

/* Playhead styling */
.timeline-playhead {
  pointer-events: none !important;
  transition: none !important;
  position: absolute !important;
  overflow: hidden !important;
}

/* Remove any purple/brown side borders */
.dashboard-card, 
.timeline-container,
.timeline-content,
.timeline-section {
  border-left: none !important;
  border-right: none !important;
}
